import React from "react"
import { fetchDemoDetails } from "../request"
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import { Spinner, Container, Form, FormControl, Button, Modal, Row, Col } from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SimpleCard from "./misc/simple-card"
import Switch from "react-switch";

class NotificareContent extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.timeout = 0;
    this.state = {
      notificare: null,
      isReady: false,
      isLoadingInbox: false,
      inboxItems: [],
      eventName: "",
      eventData: "",
      isProcessingEventForm: false,
      isEventFormSuccess: false,
      hasEventNameError: false,
      hasEventServerError: false,
      showEventFormModal: false,
      assets: [],
      assetName: "",
      isProcessingAssetForm: false,
      isAssetFormSuccess: false,
      hasAssetNameError: false,
      hasAssetServerError: false,
      isNotificationsEnabled: false,
      isLocationServicesEnabled: false,
      isDnDEnabled: false,
      dndStart: "01:00",
      dndEnd: "07:00",
      isTagPressEnabled: false,
      isTagNewsletterEnabled: false,
      isTagEventsEnabled: false,
      userDataFields: [],
      myDevice: null,
      isRegisteringDevice: false,
    }
  }

  componentDidMount() {

    /*
    let mapsScript = document.createElement('script');
    mapsScript.id = 'mapsJSLib';
    mapsScript.type = 'text/javascript';
    mapsScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyADapK7Gnrb34RvfO-IwlhrMPlF-DF6dPM&libraries=geometry";

    if (!document.getElementById("mapsJSLib")) {
      document.body.appendChild(mapsScript);
    }
    */

    let notificareScript = document.createElement('script');
    notificareScript.id = 'notificareJSLib';
    notificareScript.type = 'text/javascript';
    notificareScript.src = "https://cdn.notifica.re/libs/html5/v2/latest/notificare.min.js";

    if (!document.getElementById("notificareJSLib")) {
      document.body.appendChild(notificareScript);
      notificareScript.onload = () => {
        this.initNotificare();
      }
    } else {
      this.initNotificare();
    }

  }

  initNotificare = () => {

    let appHost = "http://localhost:8001",
      code = 'gpyqx52r9n6ktoloowqexkpq',
      cloud = 'cloud-test',
      host = document.location.host.split('.');

    if (host.length > 1) {
      if (host[1] === 'demo-test') {
        appHost = 'https://' + host[0] + '.' + host[1] + '.' + host[2] + '.com';
        code = host[0];
        cloud = 'cloud-test';
      } else if (host[1] === 'demo') {
        appHost = 'https://' + host[0] + '.' + host[1] + '.' + host[2] + '.com';
        code = host[0];
        cloud = 'cloud';
      } else {
        code = "xxx";
        cloud = 'cloud';
      }
    }

    fetchDemoDetails('https://' + cloud + '.notifica.re/api/download/demo/code/' + code).then((response) => {

      let notificare = new window.Notificare({
        "useTestEnv": (cloud === 'cloud-test'),
        "appHost": appHost,
        "appVersion": "2.0",
        "appKey": response.data.demo.applicationKey,
        "appSecret": response.data.demo.applicationSecret,
        "soundsDir": "/sounds/",
        "serviceWorker": "/sw.js",
        "geolocationOptions": {
          "timeout": 60000,
          "enableHighAccuracy": true,
          "maximumAge": 100000
        }
      });

      this.setState({
        notificare: notificare
      });

      notificare.launchWithAutoOnBoarding({
        text: this.props.intl.formatMessage({ id: "notificare.onBoarding.text" }),
        cancelText: this.props.intl.formatMessage({ id: "notificare.onBoarding.cancelText" }),
        acceptText: this.props.intl.formatMessage({ id: "notificare.onBoarding.acceptText" }),
        retryAfterInterval: 24, //in hours, defaults to 24 hours if not present
        showAfterDelay: 1 //in seconds, defaults to immediately if not present
      });

      notificare.didUpdateBadge = (badge) => {
        this.handleBadge(badge);
      }

      notificare.didRegisterDevice = () => {
        if (notificare.isLocationServicesEnabled()) {
          notificare.startLocationUpdates();
        }
        this.setState({
          myDevice: this.state.notificare.myDevice(),
          isRegisteringDevice: false
        });
      }

      notificare.didFailToRegisterDevice = (e) => {
        console.log('didFailToRegisterDevice', e);
      }

      notificare.didFailToRegisterForNotifications = (e) => {
        console.log('didFailToRegisterForNotifications', e);
      }

      notificare.didUpdateLocation = (location) => {
        console.log('didUpdateLocation', location);
      }

      notificare.didFailToUpdateLocation = (e) => {
        console.log('didFailToUpdateLocation', e);
      }

      notificare.didReceiveNotification = (notification) => {
        this.handleInboxItems();
        console.log('didReceiveNotification', notification);
      }


      notificare.didReceiveUnknownNotification = (notification) => {
        console.log('didReceiveUnknownNotification', notification);
      }


      notificare.didReceiveWorkerPush = (notification) => {
        console.log('didReceiveWorkerPush', notification);
      }


      notificare.didReceiveSystemNotification = (notification) => {
        console.log('didReceiveSystemNotification', notification);
      }

      notificare.didOpenNotification = (notification) => {
        notificare.presentNotification(notification); //Default UI for automatically handle all types of notifications
        if (notification.type === 're.notifica.notification.InAppBrowser') {
          let content = (notification.content && notification.content.length > 0) ? notification.content[0].data : null;
          if (content) {
            window.location.href = content;
          }
        }
        this.handleInboxItems();
      }

      notificare.shouldPerformActionWithURL = (url) => {
        window.location.href = url;
      }


      notificare.onReady = () => {
        this.setState({
          isReady: true
        });
        /*if (this.props.contentType === "store") {
          this.handleMap();
        } else*/ if (this.props.contentType === "inbox") {
          this.handleInboxItems();
        }

        if (notificare.isWebPushEnabled()) {
          this.setState({
            isNotificationsEnabled: true
          });
        } else {
          this.setState({
            isNotificationsEnabled: false
          });
        }

        if (notificare.isLocationServicesEnabled()) {
          this.setState({
            isLocationServicesEnabled: true
          });
        } else {
          this.setState({
            isLocationServicesEnabled: false
          });
        }

        notificare.fetchDoNotDisturb().then((data) => {
          if (data) {
            this.setState({
              isDnDEnabled: true,
              dndStart: data.start,
              dndEnd: data.end
            });
          }
        });

        notificare.fetchTags().then((data) => {
          if (data.includes("tag_press")) {
            this.setState({
              isTagPressEnabled: true
            });
          }
          if (data.includes("tag_newsletter")) {
            this.setState({
              isTagNewsletterEnabled: true
            });
          }
          if (data.includes("tag_events")) {
            this.setState({
              isTagEventsEnabled: true
            });
          }
        });

        notificare.fetchUserData().then((fields) => {
          this.setState({
            userDataFields: fields
          });
        }).catch((e) => {
          this.setState({
            userDataFields: []
          });
        });
      }

    }).catch(() => {
      //Hide navigation if no valid code can be retrieved
      let navigation = document.getElementById("demoNavigation");
      navigation.style.display = "none";
    });
  }

  handleBadge(badge){
    setTimeout(() => {
      let badgeElement = document.getElementById("notificareBadge");
      if (badge && parseInt(badge) > 0) {
        badgeElement.style.visibility = "visible";
        badgeElement.innerHTML = badge;
      } else {
        badgeElement.style.visibility = "hidden";
        badgeElement.innerHTML = "";
      }
    }, 1000);
  }

  handleInboxItems(){
    this.setState({
      isLoadingInbox: true
    });
    this.state.notificare.inboxManager.fetchInbox(null, 0, 250).then((response) => {
      this.setState({
        isLoadingInbox: false,
        inboxItems: response.inboxItems
      });
    }).catch(() => {
      this.setState({
        isLoadingInbox: false,
        inboxItems: []
      });
    });
  }

  openInboxItem(inboxItem){
    this.state.notificare.inboxManager.openInboxItem(inboxItem).then((response) => {
      this.state.notificare.presentNotification(response);
      if (response.type === 're.notifica.notification.InAppBrowser') {
        let content = (response.content && response.content.length > 0) ? response.content[0].data : null;
        if (content) {
          window.location.href = content;
        }
      }
      this.handleInboxItems();
    });
  }

  removeInboxItem(inboxItem){
    this.state.notificare.inboxManager.removeFromInbox(inboxItem).then((response) => {
      this.handleInboxItems();
    });
  }

  /*
  handleMap(){

    let map = new window.google.maps.Map(document.getElementById("storeLocator"), {
      zoom: 10,
      center: new window.google.maps.LatLng(51.92802,4.4553871)
    });

    this.state.notificare.performCloudAPIRequest('GET', '/region').then((response) => {

      if (response.regions && response.regions.length > 0) {

        let points = [];

        response.regions.forEach((region) => {

          if (region.advancedGeometry &&
            region.advancedGeometry.coordinates &&
            region.advancedGeometry.coordinates.length > 0 &&
            region.advancedGeometry.coordinates[0].length > 0) {


            let polygon = [];
            region.advancedGeometry.coordinates[0].forEach((coords) => {
              polygon.push({
                lat: coords[1],
                lng: coords[0]
              });
            });

            let polygonShape = new window.google.maps.Polygon({
              paths: polygon,
              strokeColor: '#232c2a',
              strokeOpacity: 0.8,
              strokeWeight: 5,
              fillColor: '#232c2a',
              fillOpacity: 0.5,
              zIndex: 1,
              editable: false,
              draggable: false
            });

            polygonShape.setMap(map);

            let c = new window.google.maps.Circle({
              map: map,
              center: new window.google.maps.LatLng(region.geometry.coordinates[1], region.geometry.coordinates[0]),
              radius: region.distance,
              strokeColor: '#232c2a',
              strokeOpacity: 0,
              strokeWeight: 5,
              fillColor: '#232c2a',
              fillOpacity: 0,
              zIndex: 1,
              editable: false
            });

            points.push(c);

          } else {

            let c = new window.google.maps.Circle({
              map: map,
              center: new window.google.maps.LatLng(region.geometry.coordinates[1], region.geometry.coordinates[0]),
              radius: region.distance,
              strokeColor: '#232c2a',
              strokeOpacity: 0.8,
              strokeWeight: 5,
              fillColor: '#232c2a',
              fillOpacity: 0.5,
              zIndex: 1,
              editable: false
            });

            points.push(c);

          }

        });

        if (points && points.length > 0) {
          var bounds = new window.google.maps.LatLngBounds();
          points.forEach((point) => {
            bounds.union(point.getBounds());
          });
          map.fitBounds(bounds);
        }

      }
    });
  }
   */

  handleSubmitEventForm = (e) => {
    e.preventDefault();

    this.setState({
      isProcessingEventForm: true,
      isEventFormSuccess: false,
      hasEventNameError: false,
      hasEventServerError: false
    });

    if (!this.state.eventName) {

      this.setState({
        isProcessingEventForm: false,
        hasEventNameError: true
      });

    } else {

      let data = null;
      if (this.state.eventData){
        try {
          data = JSON.parse(this.state.eventData);
        } catch(e){
          console.log(e);
        }
      }

      this.state.notificare.logCustomEvent(this.state.eventName, data).then(() => {
        this.setState({
          eventName: "",
          eventData: "",
          isProcessingEventForm: false,
          isEventFormSuccess: true,
          showEventFormModal: true
        });
      }).catch(() => {
        this.setState({
          isProcessingEventForm: false,
          hasEventServerError: true,
          showEventFormModal: true
        });
      });

    }

  }

  handleEventFieldsChange = (e) => {
    if (e && e.target && e.target.name) {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    this.setState({
      hasEventNameError: false
    });
  }

  closeEventFormModal = () => {
    this.setState({
      showEventFormModal: false
    });
  }

  handleAssetNameChange = (e) => {
    if (e && e.target && e.target.name) {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    this.setState({
      hasAssetNameError: false
    });
  }

  handleAssetFormSubmit = (e) => {
    e.preventDefault();

    this.setState({
      isProcessingAssetForm: true,
      isAssetFormSuccess: false,
      hasAssetNameError: false,
      hasAssetServerError: false
    });

    if (!this.state.assetName) {

      this.setState({
        isProcessingAssetForm: false,
        hasAssetNameError: true
      });

    } else {

      this.state.notificare.fetchAssets(this.state.assetName).then((response) => {
        this.setState({
          isProcessingAssetForm: false,
          isAssetFormSuccess: true,
          assets: response
        });
      }).catch(() => {
        this.setState({
          isProcessingAssetForm: false,
          hasAssetServerError: true,
          assets: []
        });
      });

    }
  }

  handleNotificationsChange = (e) => {
    this.setState({ isNotificationsEnabled: e });
    if (e) {
      this.state.notificare.registerForNotifications();
    } else {
      this.state.notificare.unregisterForNotifications();
    }
  }

  handleLocationServicesChange = (e) => {
    this.setState({ isLocationServicesEnabled: e });
    if (e) {
      this.state.notificare.startLocationUpdates();
    } else {
      this.state.notificare.stopLocationUpdates();
    }
  }

  handleDnDChange = (e) => {
    this.setState({ isDnDEnabled: e });
    if (e) {
      this.state.notificare.updateDoNotDisturb(this.state.dndStart, this.state.dndEnd);
    } else {
      this.state.notificare.clearDoNotDisturb();
    }
  }

  handleDnDTimesChange = (e) => {
    if (e && e.target && e.target.name) {
      this.setState({
        [e.target.name]: e.target.value
      });

      if (e.target.name === "dndStart") {
        this.state.notificare.updateDoNotDisturb(e.target.value, this.state.dndEnd);
      }

      if (e.target.name === "dndEnd") {
        this.state.notificare.updateDoNotDisturb(this.state.dndStart, e.target.value);
      }
    }
  }

  handleTagPressChange = (e) => {
    this.setState({ isTagPressEnabled: e });
    if (e) {
      this.state.notificare.addTag("tag_press");
    } else {
      this.state.notificare.removeTag("tag_press");
    }
  }

  handleTagNewsletterChange = (e) => {
    this.setState({ isTagNewsletterEnabled: e });
    if (e) {
      this.state.notificare.addTag("tag_newsletter");
    } else {
      this.state.notificare.removeTag("tag_newsletter");
    }
  }

  handleTagEventsChange = (e) => {
    this.setState({ isTagEventsEnabled: e });
    if (e) {
      this.state.notificare.addTag("tag_events");
    } else {
      this.state.notificare.removeTag("tag_events");
    }
  }

  handleUserDataFieldsChange = () => {
    this.setState({
      userDataFields: this.state.userDataFields
    });
    let userDataFields = this.state.userDataFields.map((f) => {
      return {
        key: f.key,
        value: f.value,
      };
    });
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.state.notificare.updateUserData(userDataFields);
    }, 300);
  }

  handleLogin = () => {
    this.setState({
      isRegisteringDevice: true
    });
    let now = new Date().getTime();
    this.state.notificare.registerDevice(`usr${now}`, `usr${now}`);
    setTimeout(() => {
      this.setState({
        myDevice: this.state.notificare.myDevice(),
        isRegisteringDevice: false
      });
    }, 2000);
  }

  handleLogout = () => {
    this.setState({
      isRegisteringDevice: true
    });
    this.state.notificare.registerDevice(null, null);
    setTimeout(() => {
      this.setState({
        myDevice: this.state.notificare.myDevice(),
        isRegisteringDevice: false
      });
    }, 2000);
  }

  render() {
    if (this.props.contentType === "home") {

      return null

    } else if (this.props.contentType === "inbox") {

      if (this.state.isLoadingInbox) {
        return (
          <div className={`notificare loading`}>
            <Spinner animation="border" variant="dark" />
          </div>
        )
      } else {
        return (
          <Container>
            <div className={`notificare inbox`}>
              <div className={`inbox-items`}>
                {this.state.inboxItems.map((inboxItem, i) => {
                  return (
                    <div key={i} className={inboxItem.opened ? `inbox-item opened`: `inbox-item`}>
                      <div className={`remove-item`}><a href={`/`} className={`button`} onClick={(e) => {
                        e.preventDefault();
                        this.removeInboxItem(inboxItem);
                      }}>&times;</a></div>
                      <a href={`/`} className={`message-area`} onClick={(e) => {
                        e.preventDefault();
                        this.openInboxItem(inboxItem);
                      }}>
                        <div className={`status`}>
                          <div className={`circle`}></div>
                        </div>
                        {inboxItem.attachment &&
                          <div className={`attachment`}><img className={`image`} src={inboxItem.attachment.uri} alt={``} /></div>
                        }
                        <div className={`text`}>
                          {inboxItem.title &&
                            <div className={`title`}>{inboxItem.title}</div>
                          }
                          {inboxItem.subtitle &&
                            <div className={`subtitle`}>{inboxItem.subtitle}</div>
                          }
                          <div className={`message`}>{inboxItem.message}</div>
                        </div>
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </Container>
        )
      }

    } else if (this.props.contentType === "store") {

      return (
        <Container>
          <div className={`notificare store`}>
            <div id={`storeLocator`} className={`map`}></div>
          </div>
        </Container>
      )


    } else if (this.props.contentType === "events") {

      return (
        <Container>
          <div className={`notificare events`}>
            <div className={`event-form`}>
              <div className={`text`}>
                <h1><FormattedMessage id="notificare.eventForm.title" /></h1>
                <p><FormattedMessage id="notificare.eventForm.text" /></p>
              </div>
              <Form className={`form-area`} onSubmit={this.handleSubmitEventForm}>
                <div className="form-field-group">
                  <Form.Label className="form-label"><FormattedMessage id="notificare.eventForm.event.label" /></Form.Label>
                  <FormControl type="text" value={this.state.eventName} name={`eventName`} className="form-field" onChange={this.handleEventFieldsChange} />
                  {this.state.hasEventNameError === true &&
                    <>
                      <div className={`feedback-text error`}><FormattedMessage id="notificare.eventForm.event.error" /></div>
                      <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                    </>
                  }
                </div>
                <div className="form-field-group">
                  <Form.Label className="form-label"><FormattedMessage id="notificare.eventForm.eventData.label" /></Form.Label>
                  <Form.Control as="textarea" rows="3" value={this.state.eventData} name={`eventData`} placeholder={this.props.intl.formatMessage({id: "notificare.eventForm.eventData.placeholder"})} className="form-field" onChange={this.handleEventFieldsChange} />
                </div>
                <div className="d-grid gap-2">
                  <Button className={`form-button`} disabled={this.state.isProcessingEventForm} variant="secondary" type="submit">
                    {this.state.isProcessingEventForm &&
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                    <FormattedMessage id="notificare.eventForm.button" />
                  </Button>
                </div>
              </Form>
              <Modal show={this.state.showEventFormModal} onHide={this.closeEventFormModal}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {this.state.hasEventServerError === true &&
                      <>
                        <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="exclamation-circle" />
                        <FormattedMessage id="notificare.eventForm.alerts.titles.error" />
                      </>
                    }
                    {this.state.isEventFormSuccess === true &&
                      <>
                        <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="check-circle" />
                        <FormattedMessage id="notificare.eventForm.alerts.titles.success" />
                      </>
                    }
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className={`text-center m-3`}>
                    {this.state.hasEventServerError === true &&
                      <FormattedMessage id="notificare.eventForm.alerts.error" />
                    }
                    {this.state.isEventFormSuccess === true &&
                      <FormattedMessage id="notificare.eventForm.alerts.success" />
                    }
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </Container>
      )

    } else if (this.props.contentType === "assets") {

      return (
        <Container>
          <div className={`notificare assets`}>
            <div className={`asset-form`}>
              <div className={`text`}>
                <h1><FormattedMessage id="notificare.assetForm.title" /></h1>
                <p><FormattedMessage id="notificare.assetForm.text" /></p>
              </div>
              <Form className={`form-area`} onSubmit={this.handleAssetFormSubmit}>
                <FormControl type="text" value={this.state.assetName} name={`assetName`} placeholder={this.props.intl.formatMessage({ id: "notificare.assetForm.asset.placeholder" })} className="form-field" onChange={this.handleAssetNameChange} />
                <Button className={`button`} variant="secondary" type="submit"><FormattedMessage id="notificare.assetForm.asset.button" /></Button>
              </Form>
              {this.state.hasAssetNameError === true && <div className={`form-feedback`}><FormattedMessage id="notificare.assetForm.asset.error" /></div>}
            </div>

            <div className={`assets-list`}>
              <Row>
                {this.state.assets.map((asset, i) => {
                  return (
                    <Col key={i} lg={4}>
                      <SimpleCard content={asset} />
                    </Col>
                  )
                })}
              </Row>
            </div>
          </div>
        </Container>
      )

    } else {

      return (
        <Container>
          <div className={`notificare settings`}>
            {this.state.isReady &&
              <div className={`settings-section user-profile`}>
                {this.state.myDevice?.userID &&
                  <div className={`settings-option`}>
                    <div className={`title`}><FormattedMessage id="notificare.settingsForm.user.registered.title" />{this.state.myDevice?.userID}</div>
                    <div className={`text`}><FormattedMessage id="notificare.settingsForm.user.registered.text" /></div>
                    <div className={`button-wrapper`}>
                      <Button className={`form-button`} disabled={this.state.isRegisteringDevice} variant="danger" type="button" onClick={this.handleLogout}>
                        <FormattedMessage id="notificare.settingsForm.user.registered.button" />
                      </Button>
                    </div>
                  </div>
                }
                {!this.state.myDevice?.userID &&
                  <div className={`settings-option`}>
                    <div className={`title`}><FormattedMessage id="notificare.settingsForm.user.anonymous.title" /></div>
                    <div className={`text`}><FormattedMessage id="notificare.settingsForm.user.anonymous.text" /></div>
                    <div className={`button-wrapper`}>
                      <Button className={`form-button`} disabled={this.state.isRegisteringDevice} variant="secondary" type="button" onClick={this.handleLogin}>
                        <FormattedMessage id="notificare.settingsForm.user.anonymous.button" />
                      </Button>
                    </div>
                  </div>
                }
              </div>
            }
            <div className={`settings-section`}>
              <h1><FormattedMessage id="notificare.settingsForm.notifications.header" /></h1>
              <div className={`settings-option`}>
                <div className={`title`}><FormattedMessage id="notificare.settingsForm.notifications.notifications.title" /></div>
                <div className={`text`}><FormattedMessage id="notificare.settingsForm.notifications.notifications.text" /></div>
                <div className={`switch-wrapper`}>
                  <Switch
                    checked={this.state.isNotificationsEnabled}
                    onChange={this.handleNotificationsChange}
                    onColor="#F2F4F6"
                    onHandleColor="#2B42F7"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="notifications-switch"
                  />
                </div>
              </div>
              <div className={`settings-option`}>
                <div className={`title`}><FormattedMessage id="notificare.settingsForm.notifications.location.title" /></div>
                <div className={`text`}><FormattedMessage id="notificare.settingsForm.notifications.location.text" /></div>
                <div className={`switch-wrapper`}>
                  <Switch
                    checked={this.state.isLocationServicesEnabled}
                    onChange={this.handleLocationServicesChange}
                    onColor="#F2F4F6"
                    onHandleColor="#2B42F7"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="locations-switch"
                  />
                </div>
              </div>
              <div className={`settings-option`}>
                <div className={`title`}><FormattedMessage id="notificare.settingsForm.notifications.dnd.title" /></div>
                <div className={`text`}><FormattedMessage id="notificare.settingsForm.notifications.dnd.text" /></div>
                <div className={`switch-wrapper`}>
                  <Switch
                    checked={this.state.isDnDEnabled}
                    onChange={this.handleDnDChange}
                    onColor="#F2F4F6"
                    onHandleColor="#2B42F7"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="dnd-switch"
                  />
                </div>
              </div>
              {this.state.isDnDEnabled &&
                <>
                  <div className={`settings-option dnd`}>
                    <div className={`title`}><FormattedMessage id="notificare.settingsForm.notifications.dndStart.title" /></div>
                    <div className={`input-wrapper`}>
                      <FormControl type="text" value={this.state.dndStart} name={`dndStart`} className="form-field" onChange={this.handleDnDTimesChange} />
                    </div>
                  </div>
                  <div className={`settings-option dnd`}>
                    <div className={`title`}><FormattedMessage id="notificare.settingsForm.notifications.dndEnd.title" /></div>
                    <div className={`input-wrapper`}>
                      <FormControl type="text" value={this.state.dndEnd} name={`dndEnd`} className="form-field" onChange={this.handleDnDTimesChange} />
                    </div>
                  </div>
                </>
              }
            </div>
            {this.state.userDataFields.length > 0 &&
              <div className={`settings-section`}>
                <h1><FormattedMessage id="notificare.settingsForm.userDataFields.header" /></h1>
                {this.state.userDataFields.map((item, i) => {
                  return(
                    <div key={i} className={`settings-option user-data-field`}>
                      <div className={`title`}>{item.label}</div>
                      {item.type === 'text' &&
                        <div className={`input-wrapper`}>
                          <FormControl
                            type="text"
                            value={item.value || ""}
                            name={item.key}
                            className="form-field"
                            onChange={(e) => {
                              item.value = e?.target?.value;
                              this.handleUserDataFieldsChange();
                            }}
                          />
                        </div>
                      }
                      {item.type === 'number' &&
                        <div className={`input-wrapper`}>
                          <FormControl
                            type="number"
                            value={item.value || ""}
                            name={item.key}
                            className="form-field"
                            onChange={(e) => {
                              item.value = e?.target?.value;
                              this.handleUserDataFieldsChange();
                            }}
                          />
                        </div>
                      }
                      {item.type === 'date' &&
                        <div className={`input-wrapper`}>
                          <FormControl
                            type="date"
                            value={item.value ? new Date(item.value).toISOString().slice(0, 10) : null}
                            name={item.key}
                            className="form-field"
                            onChange={(e) => {
                              item.value = e?.target?.value;
                              this.handleUserDataFieldsChange();
                            }}
                          />
                        </div>
                      }
                      {item.type === 'boolean' &&
                        <div className={`switch-wrapper`}>
                          <Switch
                            checked={item.value === 'true' ? true : false}
                            onChange={(e) => {
                              item.value = e ? 'true' : 'false';
                              this.handleUserDataFieldsChange();
                            }}
                            onColor="#F2F4F6"
                            onHandleColor="#2B42F7"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                          />
                        </div>
                      }
                    </div>
                  )
                })}
              </div>
            }
            <div className={`settings-section`}>
              <h1><FormattedMessage id="notificare.settingsForm.tags.header" /></h1>
              <div className={`settings-option`}>
                <div className={`title`}><FormattedMessage id="notificare.settingsForm.tags.press.title" /></div>
                <div className={`text`}><FormattedMessage id="notificare.settingsForm.tags.press.text" /></div>
                <div className={`switch-wrapper`}>
                  <Switch
                    checked={this.state.isTagPressEnabled}
                    onChange={this.handleTagPressChange}
                    onColor="#F2F4F6"
                    onHandleColor="#2B42F7"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="tag-press-switch"
                  />
                </div>
              </div>
              <div className={`settings-option`}>
                <div className={`title`}><FormattedMessage id="notificare.settingsForm.tags.newsletter.title" /></div>
                <div className={`text`}><FormattedMessage id="notificare.settingsForm.tags.newsletter.text" /></div>
                <div className={`switch-wrapper`}>
                  <Switch
                    checked={this.state.isTagNewsletterEnabled}
                    onChange={this.handleTagNewsletterChange}
                    onColor="#F2F4F6"
                    onHandleColor="#2B42F7"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="tag-newsletter-switch"
                  />
                </div>
              </div>
              <div className={`settings-option`}>
                <div className={`title`}><FormattedMessage id="notificare.settingsForm.tags.events.title" /></div>
                <div className={`text`}><FormattedMessage id="notificare.settingsForm.tags.events.text" /></div>
                <div className={`switch-wrapper`}>
                  <Switch
                    checked={this.state.isTagEventsEnabled}
                    onChange={this.handleTagEventsChange}
                    onColor="#F2F4F6"
                    onHandleColor="#2B42F7"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="tag-events-switch"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      )

    }

  }
}

export default injectIntl(NotificareContent)